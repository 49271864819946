import { render, staticRenderFns } from "./VerticalNavMenu.vue?vue&type=template&id=5ae69df2&scoped=true&"
import script from "./VerticalNavMenu.vue?vue&type=script&lang=js&"
export * from "./VerticalNavMenu.vue?vue&type=script&lang=js&"
import style0 from "./VerticalNavMenu.vue?vue&type=style&index=0&id=5ae69df2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae69df2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VImg,VList,VNavigationDrawer,VSlideXTransition})

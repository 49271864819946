require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#133438',
        accent: '#037c9c',
        secondary: '#8A8D93',
        success: '#56ca00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        customize: '#0dcaf5',
      },
      dark: {
        primary: '#286f7b',
        accent: '#0191b4',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        customize: '#0dcaf5',
      },
    },
  },
}
